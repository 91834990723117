h1{
  font-weight: 800;
}

.pointer{
  cursor: pointer;
}

.orange{
  color: orange;
}

.white{
  color: white;
}

/* Navbar */
.navbar-toggler-icon {
  border: none; /* Remove the border */
  width: 1.5em; /* Adjust the width as needed */
  height: 1.5em; /* Adjust the height as needed */
}

.navbar-toggler {
  border-color: transparent; /* Change the border color to white */
}

.navbar-toggler:focus {
  border-color: transparent; /* Change the border color to white */
}

/* Page Banner */
.page-banner{
  height: 35vh;
  background-size: cover;
  background-position: center;
  background-repeat: none;
  @media (min-width: 2065px){
    height: 50vh;
  }
}

.page-banner-mask{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.contact-page-banner-bg{
  background-image: url('./media/images/services/bathroom-2-dark.webp');
}

.about-page-banner-bg{
  background-image: url('./media/images/services/kitchen-dark.webp');
}

.bathrooms-page-banner-bg{
  background-image: url('./media/images/services/bathroom-3.webp');
  /* background-position:; */
}

.kitchens-page-banner-bg{
  background-image: url('./media/images/services/kitchen-2.webp');
}

.complete-page-banner-bg{
  background-image: url('./media/images/services/home.webp');
}


/* Jumbotron */
.jumbotron {
  position: relative; /* Ensure the parent div is positioned */
  width: 100%; /* Optionally, set the width of the parent div */
  height: 96vh; /* Optionally, set the height of the parent div */
  overflow: hidden; /* Hide any overflow content */
  background-color: black;
}

.jumbotron-video {
  opacity: 50%;
  position: absolute; /* Position the video absolutely within its parent */
  top: 0;
  left: 0;
  width: 100%; /* Make the video fill the width of the parent */
  height: 100%; /* Make the video fill the height of the parent */
  object-fit: cover; /* Ensure the video fills the entire container without stretching */
}

.landing-heading-mask{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; /* Added to stack items vertically */
  justify-content: center;
  align-items: center;
}

.landing-heading{
  color: white;
}

.typing-animation{
  animation: blink-caret .75s step-end infinite;
  border-right: .30em solid orange;
  padding-right: 5px;
}

.typing-animation h1{
  margin-bottom: 0;
  color: orange;
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange; }
}

.landing-btn{
  margin-top: 25px;
}

/* Stats */
.stats-container{
  margin: 40px auto;
}

.stats-content{
  margin-top: 50px;
}

.stats-item{
  @media (max-width: 767px){
    margin-top: 25px;
  }
}

.stats-count{
  color: orange;
  font-weight: 700;
}

/* Services - Homepage */
.homepage-services{
  margin-top: 50px;
}

.homepage-services-card{
  @media (max-width: 767px){
    margin-bottom: 50px;
  }
}

.homepage-services h3{
  font-weight: 800;
  /* margin-bottom: 0; */
}

.homepage-services p{
  margin-bottom: 0;
}

.homepage-services img{
  max-width: 100%;
}

/* Info */
.info{
  margin-top: 50px;
  background-color: #222222;
  color: white;
}

.info-container{
  /* padding: 50px 0; */
  padding-top: 50px;
  padding-bottom: 50px;
}

.info p{
  margin-bottom: 0;
}

.info h2{
  font-weight: 700;
}

.info-card{
  @media (max-width: 991px){
    margin-bottom: 50px;
  }
}

/* Carousel */
.homepage-carousel img{
  max-height: 80vh;
  width: 100%;
  object-fit: cover;
}

/* Contact */
.contact-us{
  padding: 50px 0;
  color: white;
  font-size: 18px;
  background-color: #222222;
}

.contact-us-heading{
  font-weight: 700;
  color: white;
}

.contact-us-form{
  max-width: 500px;
  @media (min-width: 768px){
    margin-left: auto;
    margin-right: auto;
  }
}

.contact-us-info{
  margin-bottom: 50px;
}

/* About Page */
.about-content{
  margin-top: 75px;
  margin-bottom: 50px;
}

.about-founder-img{
  border-radius: 25px;
  max-width: 100%;
  @media (max-width: 767px){
    margin-bottom: 25px;
  }
}

/* Services */
.services-intro{
  margin-top: 50px;
  margin-bottom: 50px;
}

.services-intro h3{
  font-weight: 700;
}

.services-intro-content{
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.services-pageservices{
  background-color: #222222;
}

.services-pageservices-offer{
  padding-top: 50px;
  padding-bottom: 50px;
}

.services-pageservices-img{
  border-radius: 25px;
  max-width: 100%;
  @media (max-width: 767px){
    margin-bottom: 25px;
  }
}

.services-page-li::marker{
  color: orange;
}

/* Process Accordion */

.process-section{
  padding-top: 50px;
  padding-bottom: 5px;
}

/* Accordion */
.accordion-heading{
  font-weight: 800;
}

.accordion-text{
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}

.accordion{
  margin-bottom: 75px;
  margin-top: 30px;
  background-color: transparent;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.accordion-item{
  border-right: none;
  border-left: none;
  background-color: transparent;
}

.accordion-item:first-of-type{
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion-item:last-of-type{
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion-button:not(.collapsed) {
  color: black;
  background-color: transparent;
}

.accordion-button {
  color: black;
  background-color: transparent;
}

.accordion-button::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion-button h4{
  font-weight: 700;
  color: black;
}


/* Footer */
.footer {
  padding: 30px 0;
  background-color: black;
}

.footer a{
  color: white;
  font-size: 14px;
  text-decoration: none;
  margin: 0 15px;
  @media (max-width: 480px){
    margin: 15px 15px;
    display: block;
  }
}

.footer a:hover{
  cursor: pointer;
}

.footer-divider{
  color: white;
  margin: 30px 25px;
}

.footer-text {
  margin-bottom: 0;
  font-size: 12px;
  color: darkgray
}
